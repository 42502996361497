<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-database-search</v-icon>仕入支払予定一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch">
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="入庫日From"
                  dense
                  type="date"
                  v-model="searchModel.paymentDateFrom"
                  :rules="[rules.paymentDateFromRule]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="入庫日To"
                  dense
                  type="date"
                  v-model="searchModel.paymentDateTo"
                  :rules="[rules.paymentDateToRule]"
                ></dp-date-picker>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          icon="mdi-file-edit"
          @click="onBtnUpdate"
          :disabled="!enabledUpdate"
          v-if="allowedAction(constants.updateActions)"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog" max-width="1800px" persistent scrollable>
          <payment-entry-form
            :inputModel="selectionRows"
            @cancel="onPaymentDialogClose"
            @updated="onPaymentDialogClose"
            v-if="editDialog"
          ></payment-entry-form>
        </v-dialog>
        <tooltip-icon-button
          icon="mdi-download"
          @click="onBtnExport"
          :disabled="!enabledCsvExport"
          v-if="allowedAction(constants.csvActions)"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-row>
          <v-col
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >支払金額：￥{{ this.subtotalTotal | comma }}</v-card-text
              ></v-card
            ></v-col
          ><v-col cols="6"
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >[選択]支払金額 (税抜)：￥{{ this.selectSubtotalTotal | comma }} ／ (税込)：￥{{
                  this.paymentAmountTotal | comma
                }}</v-card-text
              ></v-card
            ></v-col
          >
          <v-col
            ><v-card v-if="isCafereoUser" elevation="1" class="mt-2"
              ><v-card-text class="font-weight-bold ml-4 mb-4"
                >入庫数：{{ this.receiptQuantityTotal | comma }}</v-card-text
              ></v-card
            ></v-col
          >
          <v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          id="PaymentList"
          class="ag-theme-alpine"
          style="height: 98%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          rowSelection="multiple"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :columnTypes="columnTypes"
          :getRowNodeId="(data) => data.paymentCode"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="PaymentInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <payment-infos v-on:infoMaximum-event="infoMaximum = !infoMaximum" :details="selectedDetails"></payment-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import PaymentInfos from "../../components/payment/PaymentInfos.vue";
import PaymentEntryForm from "../../components/payment/PaymentEntryForm.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  MonthColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import ReceiptTypes from "../../consts/ReceiptTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import { comma } from "../../filter/NumberFilter";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";

export default {
  name: "PaymentSearch",
  data() {
    return {
      // 権限グループ
      constants: {
        updateActions: ["C050302"],
        csvActions: ["C050303"],
      },
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      autoSizeAll: null,
      imageList: [],
      infoMaximum: false,
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      gridStyle: { height: "95%" },
      searchModel: {
        paymentDateFrom: null,
        paymentDateTo: null,
      },
      paymentModel: {
        createNew: false,
      },
      editDialog: false,
      subtotalTotal: 0,
      selectSubtotalTotal: 0,
      paymentAmountTotal: 0,
      receiptQuantityTotal: 0,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpMonthColumn: MonthColumn,
      },
      selectionRows: [],
      detailColumnDefs: [
        {
          headerName: "対象月",
          field: "targetMonth",
          type: "dpMonthColumn",
        },
        { headerName: "仕入先CD", field: "companyStoreCode" },
        { headerName: "仕入先略称", field: "supplierName" },
        { headerName: "入庫日", field: "receiptDate", type: "dpFullDateColumn" },
        { headerName: "入庫区分", field: "receiptType" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "入庫数", field: "receiptQuantity", type: "dpNumericColumn" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        { headerName: "仕入掛率", field: "wholesaleRate", type: "dpPercentColumn" },
        { headerName: "仕入単価", field: "purchasePrice", type: "dpNumericColumn" },
        { headerName: "仕入合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
        {
          headerName: "消費税",
          field: "consumptionTax",
          type: "dpPercentColumn",
        },
        {
          headerName: "請求額税込",
          field: "invoiceAmount",
          type: "dpNumericColumn",
        },
        {
          headerName: "消費税対象額",
          field: "consumptionTaxTarget",
          type: "dpNumericColumn",
        },
      ],
      validSearchForm: null,
      rules: {
        paymentDateFromRule: (value) => this.paymentDateFromRules(value),
        paymentDateToRule: (value) => this.paymentDateToRules(value),
      },
    };
  },
  components: {
    AgGridVue,
    PaymentInfos,
    PaymentEntryForm,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 135 + "px";
    },
  },
  filters: {
    month: function (date) {
      if (date == null || date == "") return null;
      return moment(date).format("MM");
    },
    comma,
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize - 135 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails2(this.selectedRow.paymentCode, this.detailColumnDefs, this.gridApi);
    },
    enabledUpdate() {
      return this.selectionRows.length !== 0 && this.allowedAction(["C050302"]);
    },
    enabledCsvExport() {
      return this.allowedAction(["C050303"]);
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      let allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv({
        onlySelected: this.selectionRows.length > 0,
        fileName: `仕入支払予定一覧.csv`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
      this.rowsSelected = this.selectionRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;

      if (this.selectionRows.length === 1) {
        this.selectedRow = this.selectionRows[0];
      } else {
        this.selectedRow = null;
      }
      this.calcSelectTotalItem();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        var params = { ...this.searchModel };
        const response = await this.$store.dispatch("payment/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "仕入支払予定一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.payments).length === 0) {
          this.$dialog.warning({
            title: "仕入支払予定一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(result.payments);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
      } catch (error) {
        console.error("PaymentSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onPageSizeChanged() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onInfoMaximum() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onBtnUpdate() {
      this.editDialog = true;
    },
    onPaymentDialogClose(updateRecords) {
      this.selectionRows.forEach((row) => {
        updateRecords.forEach((record) => {
          if (record.paymentCode == row.paymentCode) {
            row.accountingRemarks = record.accountingRemarks;
            row.updateDatetime = record.updateDatetime;
          }
        });
      });
      this.gridApi.applyTransaction({ update: this.selectionRows });
      this.editDialog = false;
    },
    calcTotalItem() {
      // 支払金額の更新
      this.subtotalTotal = 0;
      this.receiptQuantityTotal = 0;
      let rowData = [];
      this.gridApi.forEachNode((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.subtotalTotal += row.invoiceAmount;
        this.receiptQuantityTotal += row.receiptQuantity;
      }
    },
    calcSelectTotalItem() {
      // 選択支払金額の更新
      this.selectSubtotalTotal = 0;
      this.paymentAmountTotal = 0;
      for (let row of this.selectionRows) {
        this.selectSubtotalTotal += row.purchaseTotalPrice;
        this.paymentAmountTotal += row.invoiceAmount;
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    paymentDateFromRules(value) {
      if (value == null || this.searchModel.paymentDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.paymentDateTo)) return "入庫日Toより前の日付を指定してください";
      return true;
    },
    paymentDateToRules(value) {
      if (value == null || this.searchModel.paymentDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.paymentDateFrom)) return "入庫日Fromより後の日付を指定してください";
      return true;
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "対象月",
        field: "targetMonth",
        type: "dpMonthColumn",
      },
      { headerName: "仕入先CD", field: "companyStoreCode" },
      { headerName: "仕入先略称", field: "supplierName" },
      { headerName: "入庫日", field: "receiptDate", type: "dpDateColumn" },
      {
        headerName: "入庫区分",
        field: "receiptType",
        valueGetter: (params) => ReceiptTypes.of(params.data.receiptType),
      },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
      { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
      { headerName: "入庫数", field: "receiptQuantity", type: "dpNumericColumn" },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      { headerName: "仕入掛率", field: "wholesaleRate", type: "dpPercentColumn" },
      { headerName: "仕入単価", field: "purchasePrice", type: "dpNumericColumn" },
      { headerName: "仕入合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
      {
        headerName: "消費税",
        field: "consumptionTax",
        type: "dpPercentColumn",
      },
      {
        headerName: "請求額税込",
        field: "invoiceAmount",
        type: "dpNumericColumn",
      },
      {
        headerName: "消費税対象額",
        field: "consumptionTaxTarget",
        type: "dpNumericColumn",
      },
      { headerName: "経理備考", field: "accountingRemarks" },
    ];

    this.rowData = [];
  },
};
</script>
