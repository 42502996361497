<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container class="grey lighten-5" v-if="details && details.length > 0">
    <v-row no-gutters>
      <v-col :style="gridStyle">
        <ag-grid-vue
          id="BillingList"
          class="ag-theme-alpine"
          style="height: 98%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :localeText="localeText"
          rowSelection="multiple"
          :suppressColumnVirtualisation="true"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
        >
        </ag-grid-vue>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import BaseBillingStatuses from "../../consts/billingStatus/BaseBillingStatuses";
import { statuses as ApiStatus } from "../../libs/api-client";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";

export default {
  name: "BillingList",
  props: ["details"],
  data() {
    return {
      gridStyle: { height: "95%" },
      columnDefs: null,
      rowData: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedPageSize: null,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      searchModel: {
        invoiceNumber: "",
      },
    };
  },
  components: {
    AgGridVue,
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize - 135 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  watch: {
    details() {
      this.onSearchClick();
    },
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 135 + "px";
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    async onSearchClick() {
      try {
        if (!this.details || this.details.length == 0) return;
        this.loadingOn();
        this.searchModel = {
          invoiceNumber: this.details[1].value,
        };
        const response = await this.$store.dispatch("billing/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "請求データ",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.billings).length === 0) {
          this.$dialog.warning({
            title: "請求データ",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(result.billings);
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("BillingSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected = selectedRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;

      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "請求ステータス",
        field: "billingStatus",
        filter: "dpSelectionFilter",
        filterParams: { options: BaseBillingStatuses.all() },
        valueGetter: (params) => BaseBillingStatuses.of(params.data.billingStatus),
      },
      { headerName: "請求CD", field: "billingNumber" },
      { headerName: "社店CD", field: "companyStoreCode" },
      { headerName: "法人名", field: "companyName" },
      { headerName: "取引先名", field: "storeName" },
      { headerName: "請求書番号", field: "invoiceNumber" },
      {
        headerName: "伝票日付",
        field: "slipDate",
        type: "dpDateColumn",
      },
      { headerName: "伝票番号", field: "slipNo" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      { headerName: "卸掛率", field: "wholesaleRate", type: "dpPercentColumn" },
      { headerName: "卸単価", field: "wholesaleUnitPrice", type: "dpNumericColumn" },
      { headerName: "出荷数", field: "quantity", type: "dpNumericColumn" },
      { headerName: "単価合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
      { headerName: "消費税", field: "consumptionTax", type: "dpPercentColumn" },
      { headerName: "請求額税込", field: "billingAmount", type: "dpNumericColumn" },
      { headerName: "消費税対象額", field: "consumptionTaxTarget", type: "dpNumericColumn" },
      {
        headerName: "請求日",
        field: "billingDate",
        type: "dpDateColumn",
      },
      {
        headerName: "締め日",
        field: "deadline",
        type: "dpDateColumn",
      },
      { headerName: "伝票備考", field: "slipNote" },
      { headerName: "PO番号", field: "poNumber" },
    ];

    this.rowData = [];
  },
};
</script>
