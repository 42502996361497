<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-check</v-icon>販売請求書一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.billingDateFrom"
                  class="mx-2"
                  label="請求日From"
                  dense
                  type="date"
                  :rules="[rules.billingedFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.billingDateTo"
                  class="mx-2"
                  label="請求日To"
                  dense
                  type="date"
                  :rules="[rules.billingedTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-text-field
              class="mx-2"
              label="会社名"
              dense
              v-model="searchModel.companyName"
              :rules="[rules.maxLength(150)]"
            ></v-text-field>
            <v-text-field
              class="mx-2"
              label="取引先名"
              dense
              v-model="searchModel.storeName"
              :rules="[rules.maxLength(150)]"
            ></v-text-field>
            <v-select
              :items="slipDateList"
              label="締め日"
              class="mx-2"
              clearable
              v-model="searchModel.slipDate"
            ></v-select>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          :disabled="!rowsSelected"
          icon="mdi-file-edit"
          @click="onBtnUpdate"
          v-if="allowedAction(constants.updateActions)"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog" max-width="1800px" persistent scrollable>
          <invoice-entry-form
            :inputModel="invoiceModel"
            @onDialogClose="onInvoiceDialogClose"
            @onEditSubmit="onInvoiceEditSubmit"
            v-if="editDialog"
          ></invoice-entry-form>
        </v-dialog>
        <tooltip-icon-button
          :disabled="!rowsSelected"
          icon="mdi-file-remove"
          @click="onBtnCancel"
          v-if="allowedAction(constants.cancelActions)"
          >キャンセル</tooltip-icon-button
        >
        <tooltip-icon-button
          :disabled="!rowsSelected"
          icon="mdi-file-download"
          @click="onBtnPrint"
          v-if="allowedAction(constants.printActions)"
          >請求書印刷</tooltip-icon-button
        >
        <v-divider vertical></v-divider>
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport" v-if="allowedAction(constants.csvActions)"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 8 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="InvoiceList"
          class="ag-theme-alpine"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          rowSelection="multiple"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          :getRowNodeId="(data) => data.invoiceNumber"
          style="height: 98%"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="InvoiceInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 4"
      >
        <v-divider vertical></v-divider>
        <invoice-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :invoiceNumber="selectedInvoiceNumber"
        ></invoice-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import InvoiceInfos from "../../components/invoice/InvoiceInfos.vue";
import InvoiceEntryForm from "../../components/invoice/InvoiceEntryForm.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import { statuses as ApiStatus } from "../../libs/api-client";
import InvoiceInvoiceTypes from "../../consts/InvoiceInvoiceTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import Validation from "../../libs/validation";
import SlipDateList from "../../consts/SlipDateList";

export default {
  name: "InvoiceSearch",
  data() {
    return {
      // 権限グループ
      constants: {
        updateActions: ["C050202"],
        cancelActions: ["C050203"],
        printActions: ["C050204"],
        csvActions: ["C050205"],
      },
      gridStyle: { height: "95%" },
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      autoSizeAll: null,
      imageList: [],
      infoMaximum: false,
      invoiceTypeificationList: ["買切", "委託", "消化仕入"],
      slipDateList: SlipDateList.all(),
      selectedPageSize: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      searchModel: {
        billingDateFrom: "",
        billingDateTo: "",
        companyName: "",
        storeName: "",
        slipDate: "",
      },
      invoiceModel: {
        createNew: false,
      },
      editDialog: false,
      divideDialog: false,
      returnDialog: false,
      invoiceReturnDialog: { show: false, width: "1800px" },
      invoiceReturnModel: {
        createNew: false,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      rules: {
        maxLength: Validation.maxLength,
        billingedFrom: (value) => this.billingedFromRules(value),
        billingedTo: (value) => this.billingedToRules(value),
      },
      validSearchForm: null,
    };
  },
  components: {
    AgGridVue,
    InvoiceInfos,
    InvoiceEntryForm,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = "請求書一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected = selectedRows.length == 1;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;

      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
    onBtnUpdate() {
      this.invoiceModel = {
        ...this.selectedRow,
      };
      this.editDialog = true;
    },
    onInvoiceDialogClose() {
      this.editDialog = false;
    },
    onInvoiceEditSubmit(invoice) {
      this.editDialog = false;
      this.selectedRow = invoice;
      this.gridApi.applyTransaction({ update: [invoice] });
    },
    async onBtnCancel() {
      const invoiceNumber = this.selectedRow.invoiceNumber;
      let messageText = `選択された請求書をキャンセルします (${invoiceNumber})`;
      const ok = await this.$dialog.confirm({ title: "キャンセル", text: messageText });
      if (ok) {
        try {
          this.loadingOn();

          var requestContents = {
            invoice: {
              invoiceNumber: this.selectedRow.invoiceNumber,
              updateDatetime: this.selectedRow.updateDatetime,
            },
          };
          const result = await this.$store.dispatch("invoice/cancel", requestContents);

          let error = result.data?.header;

          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridApi.applyTransaction({ remove: [this.selectedRow] });
              this.$dialog.notify.info(`請求書をキャンセルしました (${invoiceNumber})`, { timeout: 2300 });
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `請求書一覧`,
                text: "請求書情報が変更されているため、更新出来ませんでした。",
                actions: ["OK"],
              });
              this.$dialog.notify.error(`請求書キャンセルに失敗しました (${invoiceNumber})`, { timeout: 2300 });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("InvoiceSearch::onBtnCancel", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onBtnPrint() {
      const invoiceNumber = this.selectedRow.invoiceNumber;
      let messageText = `選択されたデータの請求書を印刷します (${invoiceNumber})`;
      const ok = await this.$dialog.confirm({ title: "請求書印刷", text: messageText });
      if (ok) {
        try {
          this.loadingOn();

          var requestContents = {
            invoice: {
              invoiceNumber: this.selectedRow.invoiceNumber,
            },
          };
          const result = await this.$store.dispatch("invoice/print", requestContents);

          let error = result.data?.header;

          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.selectedRow.isPrint = true;
              this.gridApi.applyTransaction({ update: [this.selectedRow] });

              window.open(result.data.contents.exportFilePath, "_blank");
              this.$dialog.notify.info(`請求書を印刷しました (${invoiceNumber})`, { timeout: 2300 });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("InvoiceSearch::onBtnPrint", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        const response = await this.$store.dispatch("invoice/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data?.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "販売請求書一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.invoices).length === 0) {
          this.$dialog.warning({
            title: "販売請求書一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(result.invoices);
        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("InvoiceSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onPageSizeChanged() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onInfoMaximum() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    billingedFromRules(value) {
      if (value == null || this.searchModel.billingDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.billingDateTo)) return "請求日Toより前の日付を指定してください";
      return true;
    },
    billingedToRules(value) {
      if (value == null || this.searchModel.billingDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.billingDateFrom)) return "請求日Fromより後の日付を指定してください";
      return true;
    },
  },
  beforeMount() {
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
      { headerName: "請求書番号", field: "invoiceNumber", pinned: "left" },
      { headerName: "印刷", field: "isPrint", type: "dpCheckmarkColumn" },
      {
        headerName: "請求書区分",
        field: "invoiceType",
        filter: "dpSelectionFilter",
        filterParams: { options: InvoiceInvoiceTypes.all() },
        valueGetter: (params) => InvoiceInvoiceTypes.of(params.data.invoiceType),
      },
      { headerName: "社店コード", field: "companyStoreCode" },
      { headerName: "会社名", field: "companyName" },
      { headerName: "取引先名", field: "storeName", filterParams: IncludeFilter },
      { headerName: "販売金額合計", field: "subtotal", type: "dpNumericColumn" },
      { headerName: "消費税", field: "consumptionTax", type: "dpPercentColumn" },
      { headerName: "消費税対象額", field: "consumptionTaxTarget", type: "dpNumericColumn" },
      { headerName: "請求額税込", field: "invoiceAmount", type: "dpNumericColumn" },
      { headerName: "請求書備考", field: "invoiceRemark" },
      {
        headerName: "請求日",
        field: "billingDate",
        type: "dpDateColumn",
      },
      {
        headerName: "締め日",
        field: "slipDate",
        type: "dpDateColumn",
      },
      {
        headerName: "支払期日",
        field: "paymentDate",
        type: "dpDateColumn",
      },
    ];

    this.searchModel.billingDateFrom = moment().startOf("months").format("YYYY-MM-DD");
    this.searchModel.billingDateTo = moment().endOf("months").format("YYYY-MM-DD");
    this.rowData = [];
  },
  computed: {
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.invoiceNumber, this.gridApi, this.columnApi);
    },
    selectedInvoiceNumber() {
      if (!this.selectedRow) {
        return [];
      }
      return this.selectedRow.invoiceNumber;
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
};
</script>
