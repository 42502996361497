import { createDefault } from "../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "5日" },
  { value: 2, text: "10日" },
  { value: 3, text: "15日" },
  { value: 4, text: "20日" },
  { value: 5, text: "25日" },
  { value: 6, text: "末日" },
]);
